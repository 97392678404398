import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import {Users} from '../models/users';
import {Observable, pipe } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor(private fs: AngularFirestore ) { }

  getUserInfoByID(email: string): Observable<any> {
    console.log('Email: ', email);
    return this.fs.collection('users', res => res.where('email', '==', email)).valueChanges();
  }
}
