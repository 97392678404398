export const environment = {
  production: true,
  firebase: {
    apiKey: 'AIzaSyCPdgCtyjFyz0rNJnDrjKyikW163Hnk_6k',
    authDomain: 'jetsobahamas.firebaseapp.com',
    databaseURL: 'https://jetsobahamas.firebaseio.com',
    projectId: 'jetsobahamas',
    storageBucket: 'jetsobahamas.appspot.com',
    messagingSenderId: '458059510005',
    appId: '1:458059510005:web:0cbe7ac8488cbaa52e15bc'
  },
};
